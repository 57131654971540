<!-- 对象通用列表页 -->
<template>
  <!-- 开发人员：王雪茹 -->
  <div class="objectTableMain">
    <div class="object_box">
      <div class="price_box">
        <div class="tit">
          <!-- 切换视图 -->
          <choose-view
            :object-name="tabName"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :tabStyle="tabStyle"
            :targetImg="targetImg"
            :targetFlag="true"
            @changeView="changeView"
          >
          </choose-view>
        </div>
        <div>
          <!-- 新建目标模型 -->
          <el-button
            type="primary createBtn pull-right"
            size="mini"
            style="padding: 7px 12px; margin-top: 1px; border: 0"
            @click="add"
            >{{ $t('label_new_target_model') }}</el-button
          >
        </div>
      </div>
      <div class="search">
        <!-- 列表表头说明文字 -->
        <statistics
          :total="totalSize"
          :isQueryCount="isQueryCount"
          :isChecked="isChecked"
          :field="sortFieldName"
          :show-checked-length="type !== 'kanban'"
          :time-interval="timeInterval"
          :checked-length="
            ($refs.tablePanel && $refs.tablePanel.checkedList.length) || lengths
          "
          @refresh="refresh"
        >
        </statistics>
        <div style="width: 300px; margin-right: 10px">
          <el-input
            v-model="modelKeywords"
            :placeholder="$t('label.searchs')"
            @change="changeKeyWord"
          ></el-input>
          <svg class="icon" aria-hidden="true" style="margin-top: 2px" @click="changeKeyWord"><use href="#icon-sousuo"></use></svg>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table_s">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          class="price_table"
          :empty-text="$t('label.dashboard.noRecord')"
          v-loading="targetLoding"
          :height="tableHeight"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- 模型名称 -->
          <el-table-column
            prop="name"
            :label="$t('label_model_name')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="allow-click" @click="detail(scope.row)">{{
                scope.row.name
              }}</span>
            </template>
          </el-table-column>
          <!-- 基准 -->
          <el-table-column
            prop="obj"
            :label="$t('label_benchmark')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!-- 人员、客户、产品、全部 -->
              <span v-if="scope.row.obj == 'user'">{{
                $t('label.weixin.group.member')
              }}</span>
              <span v-else-if="scope.row.obj == 'account'">{{
                $t('label.account')
              }}</span>
              <span v-else-if="scope.row.obj == '201100000006339JMl6w'">{{
                $t('lable.opppdt.addpdt.table.product')
              }}</span>
              <span v-else>{{ $t('label.category.1') }}</span>
            </template>
          </el-table-column>
          <!-- 考核（业务机会-金额） -->
          <el-table-column
            prop="object"
            :label="$t('label_assessment')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.object">{{ scope.row.object.label }}</span>
            </template>
          </el-table-column>
          <!-- 最后修改人&时间 -->
          <el-table-column
            prop="lastmodifyDate"
            :label="$t('label_last_modified_by_time')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.username }}，{{ scope.row.lastmodifyDate }}</span
              >
            </template>
          </el-table-column>
          <!-- 是否启用 -->
          <el-table-column
            prop="isenable"
            :label="$t('label_enable_or_no')"
            class="cent_s"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-checkbox
                disabled
                :value="scope.row.isenable === 'true' ? true : false"
              ></el-checkbox>
            </template>
          </el-table-column>
          <!-- 模型描述 -->
          <el-table-column
            prop="description"
            :label="$t('label_model_description')"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="address" width="100" :align="'center'">
            <template slot-scope="scope">
              <el-popover
                placement="bottom-end"
                width="10"
                popper-class="pop_small"
                :visible-arrow="false"
                trigger="hover"
              >
                <svg
                  class="icon inline_block little_hand"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use href="#icon-xiala"></use>
                </svg>
                <ul>
                  <!-- 编辑 -->
                  <li
                    @click="
                      handleTableAction(
                        scope,
                        $t('pagecreator_page_button_edit')
                      )
                    "
                  >
                    <span>{{ $t('pagecreator_page_button_edit') }}</span>
                  </li>
                  <!-- 删除 -->
                  <li
                    @click="
                      handleTableAction(
                        scope,
                        $t('component_setup_tabs_label_delete')
                      )
                    "
                  >
                    <span>{{ $t('component_setup_tabs_label_delete') }}</span>
                  </li>
                </ul>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label_chatter_delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <!-- 确认删除该条数据吗 -->
        <!-- 确认删除该条数据吗 -->
        <span style="font-size: 14px; color: #080707"
          >{{ $t('label.weixin.confirm.delete') }}</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">{{
            $t('button_partner_cancel')
          }}</el-button>
          <el-button type="primary" @click="confirmDel" size="mini"
            ><!-- 删除 -->
            {{ $t('label.delete') }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Statistics } from '@/components/index'
import { ChooseView } from '@/components/index'
import * as TargetModel from './api.js'

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper')
        selectWrap.addEventListener('scroll', function() {
          let sign = 100
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= sign) {
            binding.value()
          }
        })
      },
    },
  },
  components: {
    Statistics,
    ChooseView,
  },
  data() {
    return {
      targetImg: 'target',
      type: '',
      lengths: 0,
      tabName: this.$i18n.t('label_target_model'), //目标模型
      viewId: '',
      viewName: this.$i18n.t('label.emailobject.view.all'), //全部
      tabStyle: '',
      viewList: [
        {
          id: '',
          isdefault: '1',
          ismodify: 'true',
          label: this.$i18n.t('label.emailobject.view.all'), //全部
          isCurrent: true,
        },
        {
          id: 'user',
          isdefault: '',
          ismodify: 'true',
          label: this.$i18n.t('label.weixin.group.member'), //人员
          isCurrent: false,
        },
        {
          id: 'account',
          isdefault: '',
          ismodify: 'true',
          label: this.$i18n.t('label.account'), //客户
          isCurrent: false,
        },
        {
          id: '201100000006339JMl6w',
          isdefault: '',
          ismodify: 'true',
          label: this.$i18n.t('vue_label_normal_product_sf'), //产品
          isCurrent: false,
        },
      ],
      tableHeight: '300px',
      dialogVisible: false,
      tableData: [],
      delArr: {}, //当前记录
      modelKeywords: '', //搜索
      noDataTip: false,
      page: 1,
      totalSize: 0,
      isQueryCount: true,
      isChecked: true,
      sortFieldName: '',
      timeInterval: '',
      targetLoding: false,
      timer: '', //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.tableHeight =
        document.getElementsByClassName('home_content')[0].clientHeight - 208
    })
    this.getListModel()

    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      let currentTime = new Date()
      let interval = currentTime - this.lastRefresh

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000))
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000))
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000))
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      hours = minutes > 30 ? hours + 1 : hours
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                'label.login.access.day'
              )}${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}`
            : `${days} ${this.$i18n.t('label_tabpage_Sdfez')}`
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          'label_tabpage_hoursagoz'
        )}`
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          'label_tabpage_minutesagoz'
        )}`
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t('vue_label_commonobjects_view_secondsbefore', {
                seconds: seconds,
              })
            : this.$i18n.t('label.notification.time1')
      }
    }, 1000)
  },
  methods: {
    //滚动事件
    // loadMore () {
    //   let that = this;
    //   // let dom = document.querySelector('.el-table__body-wrapper')
    //   let dom = document.getElementsByClassName("el-table__body-wrapper")[
    //     document.getElementsByClassName("el-table__body-wrapper").length - 1
    //   ];
    //   dom.addEventListener("scroll", function () {
    //     const scrollDistance =
    //       dom.scrollHeight - dom.scrollTop - dom.clientHeight;
    //     if (scrollDistance <= 0) {
    //       //等于0证明已经到底，可以请求接口
    //       if (!this.noDataTip) {
    //         that.fnThrottle(that.append, 500, 1000)();
    //       }
    //     }
    //   });
    // },
    // 节流函数
    // fnThrottle (method, delay, duration) {
    //   var that = this;
    //   var timer = this.timer;
    //   var begin = new Date().getTime();
    //   return function () {
    //     var current = new Date().getTime();
    //     clearTimeout(timer);
    //     if (current - begin >= duration) {
    //       method();
    //       begin = current;
    //     } else {
    //       that.timer = setTimeout(function () {
    //         method();
    //       }, delay);
    //     }
    //   };
    // },
    //表格滚动懒加载
    // append () {
    //   if (!this.noDataTip) {
    //     // this.getListModel()
    //   }
    // },
    //获取价格手册列表数据
    getListModel() {
      this.targetLoding = true
      this.page = 1
      let data = {
        modelKeyword: this.modelKeywords,
        obj: this.viewId,
        // page: this.page,
        // pageSize: this.pageSize,
      }
      TargetModel.ListModel(data).then((res) => {
        if (this.page === 1) {
          this.tableData = []
        }
        if (res.data.modelList.length === 0 && this.page === 1) {
          this.tableData = []
        } else if (this.tableData.length === 0 || this.page === 1) {
          this.tableData = res.data.modelList
          this.page++
        } else {
          this.tableData = [...this.tableData, ...res.data.modelList]
          this.page++
        }
        //是否提示无更多数据
        if (res.data.modelList.length === 0) {
          this.noDataTip = true
        }
        this.totalSize = this.tableData.length
        this.targetLoding = false
      })
    },
    // 表格操作按钮
    handleTableAction(scoped, n) {
      if (n === this.$i18n.t('pagecreator_page_button_edit')) {
        this.$router.push({
          path: `/targetModel/detail`,
          query: {
            id: scoped.row.modelid,
          },
        })
        return false
      } else if (n === this.$i18n.t('component_setup_tabs_label_delete')) {
        this.delArr = scoped.row
        this.dialogVisible = true
      }
    },
    // 确认删除数据
    confirmDel() {
      TargetModel.DeleteTargetModel({
        modelid: this.delArr.modelid,
        objconid: this.delArr.modelid,
        contentconid: this.delArr.modelid,
      }).then(() => {
        this.$message.success(`${this.$i18n.t('label_tabpage_delsuccessz')}`) //删除成功
        this.getListModel()
      })
      this.dialogVisible = false
    },
    //新建价格手册
    add() {
      this.$router.push({
        path: `/targetModel/detail/`,
        query: {},
      })
    },
    //搜索
    changeKeyWord() {
      this.getListModel()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    // 详情
    detail(row) {
      // if(row.isenable === 'true'){
      //     this.$message({
      //       showClose: true,
      //       //当前模型已启用，不可编辑。
      //       message: this.$i18n.t('label.the.current.model.is.enabled.and.cannot.be.edited.'),
      //       type: 'warning'
      //     });
      //   }else{
      this.$router.push({
        path: `/targetModel/detail/`,
        query: {
          id: row.modelid,
        },
      })
      // }
    },
    refresh() {
      this.getListModel()
      this.lastRefresh = new Date()
    },
    changeView(view) {
      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label
          this.viewId = item.id
          item.isCurrent = true
        } else {
          item.isCurrent = false
        }
      })
      this.getListModel()
    },
  },
  beforeDestroy() {
    localStorage.setItem('relevantObjectLevel', 0)
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<style lang="scss" scoped>
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #f5f5f5;

    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #dedcda;
      padding: 20px;
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #dedcda;
      padding: 20px;
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
    }
    .new_dialog {
      ::v-deep .el-form-item__label:before {
        margin-left: -10px;
      }
      .el-form-item {
        margin-bottom: 12px;
      }
      .edit_ul {
        li {
          margin-bottom: 23px;
          span:first-child {
            width: 130px;
            display: inline-block;
          }
          font-size: 14px;
        }
      }
    }
    .price_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      height: 52px;
      .tit {
        line-height: 1.2;
        margin-left: 10px;
        div {
          width: 150px;
          margin-top: 3px;
          p:first-child {
            font-size: 12px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #080707;
            font-weight: 600;
          }
        }
      }

      div {
        height: 37px;
        .viewImg {
          margin-right: 10px;
          margin-left: 10px;
          width: 37px;
          float: left;
        }
        p:first-child {
          width: 100%;
          margin-top: -2px;
          margin-bottom: 0;
        }
        p:nth-child(2) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }
      .createBtn {
        height: 30px;
        margin-right: 10px;
        margin-left: 15px;
        background: #005fb2;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        padding-left: 40px;
        border: 1px solid #c8c6c6;
      }

      .icon {
        color: #888888;
        position: relative;
        top: -32px;
        margin: 0 12px;
        cursor: pointer;
      }
    }
    .search {
      float: right;
      display: flex;
      width: 100%;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: 10px;
      div {
        position: relative;
        svg {
          width: 16px;
          position: absolute;
          top: 7px;
          left: 10px;
        }
        ::v-deep .el-input__inner {
          height: 30px;
          padding-left: 32px;
          border: 1px solid #dedcda;
          font-size: 12px;
        }
      }
      .statistics {
        padding: 0 10px;
        margin-top: 14px;
        height: 0;
        ::v-deep p {
          margin-bottom: 0 !important;
        }
      }
    }
    .table_s {
      .price_table {
        ::v-deep tbody {
          border-top: 1px solid #dedcda !important;
        }
        ::v-deep .is-leaf {
          background: #fafafa;
          color: #333333;
          border-top: 1px solid #dedcda;
          border-right: 1px solid #dedcda;

          padding: 8px 0;
          .el-checkbox {
            margin-left: 4px;
          }
          .cell {
            word-break: break-word;
          }
        }
        ::v-deep td {
          padding: 9px 0;
          background: #fff;
          border-bottom: 1px solid #dedcda !important;
          border-right: 0;
        }
        .el-table__body-wrapper {
          overflow-y: auto;
          // max-height: 150px;
          // 提示语
          .noDataTip {
            height: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            background: #fff;
          }
        }
      }
    }

    .allow-click {
      color: #006dcc;
      cursor: pointer;
    }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #006dcc !important;
    border-color: #006dcc !important;
  }
}
.dialog-footer {
  button {
    padding: 7px 7px;
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  cursor: pointer;
  color: #006dcc;
}
</style>
